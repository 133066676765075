body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Quicksand',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: hsl(210, 36%, 96%);
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




/* CSS */
/* For WebKit (Chrome, Safari, etc.) */
/* You may need to use similar properties for other browser vendors */
/* This example styles the scrollbar with a custom width, color, and track color */

/* Track */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #15154D; /* Color of the scrollbar handle */
  border-radius: 6px; /* Rounded corners of the scrollbar handle */
}

/* Track background */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}